import React from "react";

import "../../App.css";
import Header from "../../components/Header/Header";
import NewsAll from "../../components/News/NewsAll";

const NewsPages = () => {
  return (
    <div style={{ padding: "1.5rem" }}>
      <Header />
      <NewsAll />
    </div>
  );
};

export default NewsPages;
