import React from "react";
import "./Back.css";

const Back = ({ title, desc }) => {
  return (
    <>
      <section className="back">
        <h1 className="title">{title}</h1>
        <h5>{desc}</h5>
      </section>
      <div className="margin"></div>
    </>
  );
};

export default Back;
