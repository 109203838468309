import React from "react";

import "./Plans.css";

import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";
import ReactWhatsapp from "react-whatsapp";

const Plans = () => {
  return (
    <div className="plans-container">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="plans-header">
        <span className="stroke-text">siap memulai</span>
        <span>perjalanan</span>
        <span className="stroke-text">bersama kami</span>
      </div>

      {/* Plans Card */}

      <div className="plans">
        {plansData.map((plan, i) => (
          <div className="plan">
            {plan.icon}
            <span>{plan.name}</span>
            <span>Rp. {plan.price}</span>

            <div className="features">
              {plan.features.map((feature, j) => (
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={j}>{feature}</span>
                </div>
              ))}
            </div>

            <div>
              <span>lihat benefit lainnya -{">"}</span>
            </div>

            <ReactWhatsapp
              className="btn"
              number="+6281212909085"
              message="Hello Aditi Psychological Center, Saya ingin melakukan konseling."
            >
              Join Now
            </ReactWhatsapp>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
