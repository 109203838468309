import "./App.css";
import "react-multi-carousel/lib/styles.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navigation from "./pages/Navigation";
import MainApp from "./pages/Home/MainApp";
import News from "./pages/News/NewsPages";
import Test from "./pages/Test";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="news" element={<News />} />
        <Route path="test" element={<Test />}>
          <Route path=":text" element={<Test />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
