import React from "react";

import { useParams } from "react-router-dom";

const Test = () => {
  const params = useParams();
  return (
    <div>
      <span>Testing {params.text}</span>
    </div>
  );
};

export default Test;
