import React from "react";

import "./Profile.css";

import { profile } from "../../data/programsData";
import Carousel from "react-multi-carousel";
import whiteTick from "../../assets/tick.png";

const Profile = () => {
  return (
    <div className="profiles-container">
      <div className="blur profiles-blur-1"></div>
      <div className="blur profiles-blur-2"></div>
      <div className="programs-header">
        <span className="stroke-text">PROFILE</span>
        <span>PSIKOLOG</span>
        <span className="stroke-text">KAMI</span>
      </div>

      {/* profiles Card */}

      <Carousel
        additionalTransfrom={0}
        autoPlay={false}
        centerMode={true}
        className="profiles"
        containerClass="container-with-dots"
        dotListClass=""
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        pauseOnHover={false}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
      >
        {profile.map((profile, i) => (
          <div className="profile">
            <img src={profile.image} alt="" />
            <span>{profile.name}</span>
            <div className="specialis">
              {profile.specialis.map((spesial, j) => (
                <div className="spesial">
                  <img src={whiteTick} alt="" />
                  <span key={j}>{spesial}</span>
                </div>
              ))}
            </div>

            <div className="buttons">
              <button className="btn">Profile Lengkap</button>
            </div>
          </div>
        ))}
      </Carousel>

      {/* <div className="profiles"></div> */}
    </div>
  );
};

export default Profile;
