import image1 from "../assets/kounseling.jpg";
import image2 from "../assets/asesmen.jpg";
import image3 from "../assets/psikoterapi1.jpg";
import image4 from "../assets/lainnya.jpg";

// psikolog
import psikolog1 from "../assets/Psikolog/nuri.png";
import psikolog2 from "../assets/Psikolog/diah.png";
import psikolog3 from "../assets/Psikolog/fina.png";
import psikolog4 from "../assets/Psikolog/Karina.png";
import psikolog5 from "../assets/Psikolog/prita.png";
import psikolog6 from "../assets/Psikolog/jiwika.png";
import psikolog7 from "../assets/Psikolog/as9.png";

export const programsData = [
  {
    image: image1,
    heading: "KONSELING PSIKOLOGI",
    details:
      "Memberikan pelayanan terkait berbagai permasalahan psikologis, dengan menggunakan metode face to face counseling...",
  },
  {
    image: image2,
    heading: "ASESMEN PSIKOLOGI",
    details:
      "Memberikan pelayanan Psikologis menggunakan alat tes yang terstandarisasi guna mendapatkan pemahaman tentang klien...",
  },
  {
    image: image3,
    heading: "PSIKOTERAPI",
    details:
      "Memberikan pelayanan menggunakan metode terapi berbasis psikologi yang telah teruji dan terstandar, untuk mengatasi gangguan mental pada klien...",
  },
  {
    image: image4,
    heading: "PELAYANAN LAIN",
    details:
      "Memberikan pelayanan berbasis psikologi yang telah teruji dan terstandar, untuk memenuhi berbagai macam kebutuhan klien...",
  },
];

export const news = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Miskonsepsi tentang Kebahagiaan",
    desc: "Hal-hal apa yang membuatmu merasa bahagia? Pertanyaan klise ini sering kali ditanyakan dalam banyak kesempatan.",
    cover: image1,
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Kebiasaan Mengkritik Diri Sendiri: Positif atau Negatif?",
    desc: "Dalam beberapa situasi, kenyataan yang kita hadapi tidak sesuai dengan hal yang kita harapkan. Perasaan kecewa yang muncul sebenarnya merupakan hal yang wajar.",
    cover: image2,
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Apakah Merasa Tidak Baik-baik Saja = Kekosongan Spiritual?",
    desc: "Pernahkah kamu mendengar pernyataan-pernyataan di sekitarmu yang mengaitkan perasaan-perasaan negatif yang manusia alami sebagai bentuk dari kekosongan spiritual?",
    cover: image3,
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Temani Ia yang Ingin Mengakhiri Hidupnya",
    desc: "Apakah kamu pernah mendengar Hari Pencegahan Bunuh Diri Sedunia atau World Suicide Prevention Day (WSPD)?",
    cover: image1,
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Online Dating Baik atau Buruk?",
    desc: "Tercatat selama pandemi, jumlah pengguna dan jumlah waktu yang dihabiskan orang untuk aplikasi ini meningkat.",
    cover: image1,
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "MEMAAFKAN (FORGIVENESS)",
    desc: "Apa yang terbesit di benak anda ketika mendengar kata lebaran ? Tunjangan hari raya? Mudik? Ketupan? Atau lainnya? Banyak hal yang terbesit ketika kita mendengan kata “lebaran” pastinya.",
    cover: image1,
  },
];

export const profile = [
  {
    id: 1,
    name: "Nuri Shabila Mushalliena M. Psi, Psikolog",
    specialis: [
      "Tumbuh Kembang Anak dan Remaja",
      "Masalah Sosio-Emosi Anak dan Remaja",
      "Pola Pengasuhan",
    ],
    image: psikolog1,
  },
  {
    id: 2,
    name: "Diah Nur Ayu, M. Psi., Psikolog",
    specialis: [
      "Tumbuh Kembang Anak dan Remaja",
      "Masalah Sosio-Emosi Anak dan Remaja",
      "Pola Pengasuhan",
    ],
    image: psikolog2,
  },
  {
    id: 3,
    name: "Fina Dwi Putri, M. Psi., Psikolog",
    specialis: [
      "Tumbuh Kembang Anak dan Remaja",
      "Masalah Sosio-Emosi Anak dan Remaja",
      "Pola Pengasuhan",
    ],
    image: psikolog3,
  },
  {
    id: 4,
    name: "Karina Delicia Budiono, M. Psi., Psikolog",
    specialis: [
      "Tumbuh Kembang Anak dan Remaja",
      "Masalah Sosio-Emosi Anak dan Remaja",
      "Pola Pengasuhan",
    ],
    image: psikolog4,
  },
  {
    id: 5,
    name: "Prita Pradipta, M. Psi., Psikolog",
    specialis: [
      "Tumbuh Kembang Anak dan Remaja",
      "Masalah Sosio-Emosi Anak dan Remaja",
      "Pola Pengasuhan",
    ],
    image: psikolog5,
  },
  {
    id: 6,
    name: "Jiwika. M. J. Hutajulu, M. Psi., Psikolog",
    specialis: [
      "Tumbuh Kembang Anak dan Remaja",
      "Masalah Sosio-Emosi Anak dan Remaja",
      "Pola Pengasuhan",
    ],
    image: psikolog6,
  },
];
