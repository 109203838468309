import React from "react";

import "./News.css";

import { news } from "../../data/programsData";
import Back from "../Common/Back/Back";

const NewsAll = () => {
  return (
    <>
      <Back
        title={"Kumpulan News & Fact serta Tips & Trick"}
        desc="02 March 2022 | admin"
      />

      <div className="card padding">
        <div className=" grid2">
          {news.map((val) => (
            <div className="shadow">
              <div className="">
                <img src={val.cover} alt="" />
              </div>
              <div className="text">
                <div className="admin flexSB">
                  <span>
                    <i className="fa fa-user"></i>
                    <label htmlFor="">{val.type}</label>
                  </span>
                  <span>
                    <i className="fa fa-calendar-alt"></i>
                    <label htmlFor="">{val.date}</label>
                  </span>
                  <span>
                    <i className="fa fa-comments"></i>
                    <label htmlFor="">{val.com}</label>
                  </span>
                </div>
                <h1>{val.title}</h1>
                <p>{val.desc}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NewsAll;
