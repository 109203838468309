import image1 from "../assets/testimoni/team1.png";
import image2 from "../assets/testimoni/team2.png";
import image3 from "../assets/testimoni/team4.png";

export const testimonialsData = [
  {
    image: image1,
    review:
      "Adapun proses kerjasama diawali dengan baik, seluruh proses direspon dengan cepat dan professional, dan proses asesmen juga dikerjakan dengan rapi. Laporan hasil asesmen yang kami terima dapat mudah dimengerti oleh pihak manajemen perusahaan, sehingga dapat memberikan feedback dan mengidentifikasi aspesk kompetensi psikologis karyawan secara lebih komprehensif.",
    name: "Kezia-HRD",
    status: "PT. Indomarco Adi Prima",
  },
  {
    image: image2,
    review:
      "Pelayanan di PT. Aditi Uniti Veritas sangat baik dan kooperatif. Psikolog-psikolognya juga baik, profesional dan sabar dalam menjelaskan dan memberikan masukan atau saran.",
    name: "Tim Bimbingan Konseling",
    status: "Universitas Pertamina",
  },
  {
    image: image3,
    review:
      "Muda dan Berkualitas, untuk menggambarkan personil2 dari Aditi dalam memberikan service. Terimakasih karena sudah membantu proses pengembangan diri untuk personil PT. Sentra Vidya Utama.",
    name: "HRD PT. Sevima",
    status: "PT. Sentra Vidya Utama",
  },
];
