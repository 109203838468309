import React from "react";

import "./Reason.css";

import image1_1 from "../../assets/psikoterapi.png";
import image1_2 from "../../assets/asesmen.jpg";
import image1_3 from "../../assets/kounseling.jpg";
import image1_4 from "../../assets/psikoterapi1.jpg";
import tick from "../../assets/tick.png";

import { partnersData } from "../../data/partnersData";
import Carousel from "react-multi-carousel";

const Reason = () => {
  return (
    <div id="reasons" className="reasons">
      <div className="left-r">
        <img src={image1_1} alt="" />
        <img src={image1_2} alt="" />
        <img src={image1_3} alt="" />
        <img src={image1_4} alt="" />
      </div>
      <div className="right-r">
        <span>beberapa alasan</span>

        <div>
          <span>
            kenapa <span className="stroke-text">harus memilih </span>
          </span>
          <span>kami?</span>
        </div>

        <div className="details-r">
          <div>
            <img src={tick} alt=""></img>
            <span>RAHASIA KAMU AMAN TERJAGA OLEH KAMI</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>CERITA KAPAN PUN & DIMANA PUN</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>BERKELUH KESAH DENGAN EXPERT-NYA</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>BANYAK MITRA KAMI YANG TERPERCAYA</span>
          </div>
        </div>

        <span
          style={{
            color: "var(--orange)",
            fontWeight: "bold",
            marginTop: "2rem",
          }}
        >
          PARTNERS TERPERCAYA KAMI
        </span>

        <Carousel
          additionalTransfrom={0}
          autoPlay
          autoPlaySpeed={3000}
          centerMode={false}
          className="partners"
          containerClass="container-with-dots"
          dotListClass=""
          focusOnSelect={false}
          infinite={false}
          itemClass=""
          pauseOnHover={false}
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={{
            desktop: {
              breakpoint: {
                max: 3000,
                min: 1024,
              },
              items: 3,
              partialVisibilityGutter: 40,
            },
            mobile: {
              breakpoint: {
                max: 464,
                min: 0,
              },
              items: 1,
              partialVisibilityGutter: 30,
            },
            tablet: {
              breakpoint: {
                max: 1024,
                min: 464,
              },
              items: 2,
              partialVisibilityGutter: 30,
            },
          }}
          rewind
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={false}
          sliderClass=""
          arrows={false}
        >
          {partnersData.map((partner, i) => (
            <img src={partner.image} alt="" />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Reason;
