import React from "react";

import "../../App.css";
import Footer from "../../components/Footer/Footer";
import Hero from "../../components/Hero/Hero";
import Join from "../../components/Join/Join";
import News from "../../components/News/News";
import Plans from "../../components/Plans/Plans";
import Profile from "../../components/Profile/Profile";
import Programs from "../../components/Programs/Programs";
import Reason from "../../components/Reasons/Reason";
import Testimonials from "../../components/Testimonials/Testimonials";

const MainApp = () => {
  return (
    <div className="App">
      <Hero />
      <Programs />
      <Reason />
      <Profile />
      <Plans />
      <News />
      <Testimonials />
      <Join />
      <Footer />
    </div>
  );
};

export default MainApp;
