import React, { useState } from "react";

import "./Header.css";

import LogoAditi from "../../assets/logo-aditi.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-router-dom";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpenened] = useState(false);
  return (
    <div className="header">
      <Link to="/">
        <img src={LogoAditi} className="logo" />
      </Link>

      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "var(--appColor)",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
          onClick={() => setMenuOpenened(true)}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem" }}
          />
        </div>
      ) : (
        <ul className="header-menu">
          <li>
            <Link
              to="/"
              onClick={() => setMenuOpenened(false)}
              span={true}
              smooth={true}
            >
              Beranda
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => setMenuOpenened(false)}
              span={true}
              smooth={true}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => setMenuOpenened(false)}
              span={true}
              smooth={true}
            >
              Tentang Kami
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => setMenuOpenened(false)}
              span={true}
              smooth={true}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              to="/"
              onClick={() => setMenuOpenened(false)}
              span={true}
              smooth={true}
            >
              Testimoni
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
