import React from "react";
import "./Hero.css";

import Header from "../Header/Header";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import NumberCounter from "number-counter";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768 ? true : false;

  return (
    <div className="hero" id="header">
      <div className="blur hero-blur"></div>
      <div className="left-h">
        <Header />
        {/* The best ad */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "218px" : "368px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>klinis konseling psychological terbaik di indonesia</span>
        </div>

        {/* hero heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">kami </span>
            <span>akan</span>
          </div>
          <div>
            <span>membantu</span> <span className="stroke-text">kamu</span>
          </div>
          <div>
            <span>
              Berhentilah bertanya-tanya apa yang Anda kuasai. Mulailah
              mengetahui dengan penilaian AditiPsyCenter. Penilaian kami
              mengidentifikasi bakat alami Anda, sehingga Anda dapat tampil
              lebih baik dalam pekerjaan Anda, membangun hubungan yang lebih
              kuat, dan mencapai pertumbuhan pribadi.
            </span>
          </div>
        </div>

        {/* Figures */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter end={1794} start={100} delay="4" preFix="+" />
            </span>
            <span>orang bahagia</span>
          </div>
          <div>
            <span>
              <NumberCounter end={5563} start={3000} delay="3.3" preFix="+" />
            </span>
            <span>jam konseling</span>
          </div>
          <div>
            <span>
              <NumberCounter end={2873} start={500} delay="2" preFix="+" />
            </span>
            <span>klien individu</span>
          </div>
        </div>

        {/* Button */}
        <div className="hero-buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>

      <div className="right-h">
        <button className="btn">Register</button>
        <button className="btn btn-l">
          <Link to="login">Login</Link>
        </button>

        <motion.div
          initial={{ right: "-1rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
          className="heart-rate"
        >
          <img src={Heart} />
          <span>Who Likes?</span>
          <span>9534 likes</span>
        </motion.div>

        {/* Hero Image */}
        {/* <img src={hero_image} className="hero-image" /> */}
        <motion.img
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
          src={hero_image_back}
          className="hero-image-back"
        />

        {/* calories */}
        <motion.div
          initial={{ right: "40rem" }}
          whileInView={{ right: "33rem" }}
          transition={transition}
          className="calories"
        >
          <img src={Calories} alt="" />
          <div>
            <span>User Visited</span>
            <span>220 visited</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
