import React from "react";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <br />
      <br />
      <br />
      <br />
      <hr style={{ borderWidth: 4, height: "10rem" }} />
      <h1
        style={{
          textAlign: "center",
        }}
      >
        404 Not Found
      </h1>
      <hr style={{ borderWidth: 4, height: "10rem" }} />
    </div>
  );
};

export default NotFound;
