import image1 from "../assets/partners/indomarco.png";
import image2 from "../assets/partners/kowika.png";
import image3 from "../assets/partners/sehatQ.png";
import image4 from "../assets/partners/un-gunadarma.png";
import image5 from "../assets/partners/un-pertamina.png";

export const partnersData = [
  {
    image: image1,
  },
  {
    image: image2,
  },
  {
    image: image3,
  },
  {
    image: image4,
  },
  {
    image: image5,
  },
  {
    image: image5,
  },
  {
    image: image5,
  },
  {
    image: image5,
  },
  {
    image: image5,
  },
];
