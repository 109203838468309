import React from "react";

import "./Footer.css";

import Logo from "../../assets/logo-aditi.png";
import { Link } from "react-scroll";
import { Link as Links } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";

const Footer = () => {
  return (
    <div className="footer-container">
      <footer>
        <div className="container padding">
          <div className="box logo">
            <img className="img" src={Logo} alt="" />
            <span>PSYCHOLOGICAL EDUCATION & LEARNING</span>
            <p>
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia.
            </p>

            <i className="fab fa-facebook-f icon"></i>
            <i className="fab fa-twitter icon"></i>
            <i className="fab fa-instagram icon"></i>
          </div>
          <div className="box link">
            <h3>Jelajahi</h3>
            <ul>
              <Link to="programs">
                <li>Tentang Kami</li>
              </Link>
              <Link to="plans">
                <li>Services</li>
              </Link>
              <Link to="newses">
                <li>News & Fact</li>
              </Link>
              <Link to="join-us">
                <li>Hubungi Kami</li>
              </Link>
            </ul>
          </div>
          <div className="box link">
            <h3>Quick Links</h3>
            <ul>
              <Link to="plans">
                <li>Pricing</li>
              </Link>

              <li>Syarat & ketentuan</li>
              <li>Privacy</li>
              <li>Testimoni</li>
            </ul>
          </div>
          <div className="box last">
            <h3>Have a Questions?</h3>
            <ul>
              <li>
                <i className="fa fa-map" style={{ marginRight: "2.3rem" }}></i>
                Komplek Perkantoran, Jl. Kalibata Tengah No 35 C RT.11/RW.7,
                Kalibata, Kec. Pancoran, Kota Jakarta Selatan, DKI Jakarta 12740
              </li>
              <li>
                <i className="fa fa-phone-alt"></i>
                +62 812 1290 9085
              </li>
              <li>
                <i className="fa fa-paper-plane"></i>
                hello@aditipsycenter.com
              </li>
            </ul>
          </div>
        </div>
      </footer>
      <div className="legal">
        <p>Copyright ©2022 All rights reserved | Aditi Psychological Center</p>
      </div>

      <div className="blur footer-blur-1"></div>
      <div className="blur footer-blur-2"></div>
    </div>
  );
};

export default Footer;
