import React from "react";

import "./Programs.css";

import { programsData } from "../../data/programsData";
import RightArrow from "../../assets/rightArrow.png";

const Programs = () => {
  return (
    <div className="programs" id="programs">
      {/* Headers */}
      <div className="programs-header">
        <span>siap untuk</span>
        <span className="stroke-text">hidup</span>
        <span>lebih</span>
        <span className="stroke-text">bahagia</span>
      </div>

      <div className="programs-categories">
        {programsData.map((program) => (
          <div className="category">
            <img src={program.image} />
            <span>{program.heading}</span>
            <span>{program.details}</span>
            <div className="join-now"></div>
          </div>
        ))}
      </div>
      <div className="blur programs-blur"></div>
    </div>
  );
};

export default Programs;
