import React from "react";
import { Link } from "react-router-dom";

import "./News.css";

import { news } from "../../data/programsData";
import Carousel from "react-multi-carousel";

const News = () => {
  return (
    <div id="newses" className="news">
      <div className="programs-header">
        <span>News & Fact</span>
        <span className="stroke-text">serta</span>
        <span>Tips & Trick</span>
      </div>

      <Carousel
        additionalTransfrom={0}
        autoPlay
        autoPlaySpeed={3000}
        centerMode={false}
        className="news-card"
        containerClass="container-with-dots"
        dotListClass=""
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        pauseOnHover={false}
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        arrows={false}
      >
        {news.map((news, i) => (
          <div className="card">
            <div className="">
              <img src={news.cover} alt="" />
            </div>
            <div className="text">
              <div className="admin flexSB">
                <span>
                  <i className="fa fa-user"></i>
                  <label htmlFor="">{news.type}</label>
                </span>
                <span>
                  <i className="fa fa-calendar-alt"></i>
                  <label htmlFor="">{news.date}</label>
                </span>
                <span>
                  <i className="fa fa-comments"></i>
                  <label htmlFor="">{news.com}</label>
                </span>
              </div>
              <Link to="news">
                <h1>{news.title}</h1>
              </Link>
              <p>{news.desc}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default News;
